import {
  Flex,
  Card,
  Text,
  images,
  Box,
  TextInput,
  FaUserAlt,
  Button,
  FaChevronRight,
  useForm,
  LoadingOverlay,
  useStyles,
  publishLib,
  usePathname,
} from '@mfe/js-asulado-uiutils';
import {
  useMsal,
  useIsAuthenticated,
  handleLogin,
  MsalProvider,
  msalInstance,
} from '@mfe/react-asulado-msal';

import { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';

export const Login = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <LoginForm />
    </MsalProvider>
  );
};

export const LoginForm = () => {
  const [loading, setLoading] = useState(false);

  // const { classes } = useStyles();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const loadingStorage = localStorage.getItem('loading') === 'true';
  const isAuth = localStorage.getItem('isAuth') === 'true';
  
  const [data, setData] = useState(null);
  const pathname = usePathname();

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) =>
        value && /^\S+@\S+\.\S+$/.test(value)
          ? null
          : 'Correo electrónico inválido',
    },
  });

  const handleSubmit = (values) => {
    setLoading(true)
    handleLogin(values.email, setLoading(true), instance);
  };

  useEffect(() => {
    if (loadingStorage) {
      setLoading(true);
    }
    if (isAuthenticated) {
      localStorage.setItem('isAuth', 'true');
      localStorage.setItem('loading', 'false');
    }
  }, [isAuthenticated, loadingStorage]);

  useEffect(() => {
    const handleAuthRedirect = async () => {
      const hash = window.location.hash;
      if (hash.includes('#code')) {
        try {
          const response = await instance.handleRedirectPromise();
          if (response) {
            localStorage.setItem('isAuthenticated', 'true');
            navigateToUrl('/app#busqueda');
          }
        } catch (error) {
          console.error(error);
        }
      } else if (isAuthenticated) {
        if (window.location.pathname === '/') {
          navigateToUrl('/app#busqueda');
        }
      } else {
        const currentPath = window.location.pathname;
        if (currentPath !== '/') {
          navigateToUrl('/');
        }
      }
    };

    handleAuthRedirect();
  }, [instance, isAuthenticated]);

/*   useEffect(() => {
    const validRoutes = ['/', '/app#busqueda', '/app#aprobacion', '/app#aprobacion'];
    if (!validRoutes.includes(window.location.pathname)) {
      navigateToUrl('/404');
    }
  }, [pathname]); */

  if (loading && (localStorage.getItem('isAuth') == null || localStorage.getItem('isAuth') == 'false')) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: '#2EBADA' }}
      />
    )
  }

  return (
    !isAuthenticated && (
      <Flex
        style={{
          width: '100vw',
          background: 'transparent',
          height: '100vh',
        }}
        justify='center'
        align='center'
        direction='column'
        wrap='wrap'
      >
        <Card
          shadow='sm'
          paddingY='3rem'
          m='auto'
          radius='md'
          sx={{ border: '0.5px solid #08c1fe', height: 'auto' }}
          w={300}
          minW={400}
        >
          <Box bg='' align='center'>
            <img
              style={{ width: '70%', height: '70%' }}
              src={images.logoAsulado}
              alt='image'
            />
            <img
              style={{ width: '50%', height: '50%' }}
              src={images.User}
              alt='image'
            />
          </Box>

          <Box mt='10%' h='100%' w='100%'>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <TextInput
                {...form.getInputProps('email')}
                description='Correo electronico'
                placeholder='usuario@emeal.nttdata.com '
                icon={<FaUserAlt size='1.2rem' />}
              />
              <Box align='center' sx={{ padding: '1rem 0' }}>
                <Button
                  w='80%'
                  radius='lg'
                  disabled={!form.isValid()}
                  // rightIcon={<FaChevronRight size='1.1rem' />}
                  style={{
                    backgroundColor: !form.isValid() ? '#c3c5c6' : '#FAEC6E',
                    color: !form.isValid() ? 'white' : '#144173',
                  }}
                  type='submit'
                >
                  Iniciar sesión
                </Button>
              </Box>
            </form>
            {/* <Text c='#18254e' ta='center' size='small'>
              Al ingresar usted acepta términos de uso y políticas de privacidad
            </Text> */}
          </Box>
        </Card>
      </Flex>
    )
  )
}
